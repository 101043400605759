.root {
}

.header {
  overflow: hidden;
  background-color: #f3f6ff;
  width: 100%;
  min-height: 73px;
  z-index: 900;
  color: #000000;
}

.main {
}

.footer {
  position: fixed;
  z-index: 3001;
}
