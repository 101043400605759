.root {
  margin: 25px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.branding {
  letter-spacing: 0.232836px;
  color: #4643d3;
}

.cta {
}

.formControl {
  display: block;
}

.inputField {
  width: 100%;
}

.button {
  background: #4643d3;
  border-radius: 28.5px;
  border: none;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.closed .root {
  margin: 50px;
}

.closed .formControl {
  display: none;
}
