* {
  box-sizing: border-box;
  /*width: 100%;*/
  /*height: 100%;*/
}

body {
  margin: 0;
  line-height: 1.6;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leaflet-container {
  height: calc(100vh - 112px);
  width: 100%;
  margin: 0 auto;
}
